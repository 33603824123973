<template>
  <payment-order-redirect>
    <template v-slot:title>
      {{ $t("entity_added", { entity: $t("card") }) }}</template
    >
  </payment-order-redirect>
</template>

<script>
import Order from "@/externalViews/Payment/Order";
import PaymentOrderRedirect from "@/externalViews/Payment/Order";

export default {
  name: "PaymentSubscriptionRedirect",
  components: { PaymentOrderRedirect },
  extends: Order,
  methods: {
    setTitle() {
      this.title = this.$t("entity_added", { entity: this.$t("card") });
    }
  }
};
</script>
